<div class="container" style="font-size:0.9rem; margin-bottom: 40px;">
  <div style="background-color: #FFF; padding:25px;">
    <div style="text-align: left;">
      <h2>Release Notes</h2>
      <small class="text-muted">Update 01-02-2024</small>
      <div style="font-size: 1em;">
        <h3>Version 2.2.12 - Oct 01, 2024</h3>
        <ul>
          <li>Trip Sort Fix</li>
          <li>Notify Customer SMS</li>
          <li>Dashboard Filter Bug</li>
          <li>Trip Request: Fixed Date</li>
          <li>Expense Photo Dispaly Issuf Fixed</li>
          <li>Checkout: Address Required</li>
        </ul>
        <h3>Version 2.2.8 - June 26, 2024</h3>
        <ul>
          <li>Trip Sort Fix</li>
          <li>Other Minor Fixes</li>
        </ul>
        <h3>Version 2.2.7 - June 21, 2024</h3>
        <ul>
          <li>Loadboard: filter by dispatcher error </li>
          <li>VIN search </li>
          <li>Fix trip revenue </li>
          <li>Clearing the input field </li>
          <li>Payment terms </li>
          <li>Account contacts tabs </li>
          <li>Add driver to order with trip, Files</li>
          <li>Super Dispatch Files</li>
          <li>Receipts: Orders not displayed sometimes on mobile </li>
          <li>Quote Form Edit Vehicle Error </li>
          <li>Custom BOL: add phone - TypeError: ctx_r0.options._id.includes is not a function </li>
          <li>Change link to photos at BOL </li>
          <li>Fix NG0955 </li>
          <li>Merge inspection: order number not displayed </li>
          <li>Trip Req. Dates on Complete </li>
          <li>Tracking portal information wrong displayed </li>
          <li>Layout Feedback </li>
          <li>Visibility other contacts/files </li>
          <li>Shop save services </li>
          <li>BOL Date  </li>
        </ul>
        <h3>Version 2.2.6 - June 7, 2024</h3>
        <ul>
          <li>Feedback - auto-resend </li>
          <li> Minor Fixes
            <ul>
              <li>Tracking</li>
              <li>Public Orders</li>
              <li>Feedbacks</li>
            </ul>
            </li>
        </ul>
        <h3>Version 2.2.4 - April 15, 2024</h3>
        <ul>
          <li>Tracking Portal UI Update</li>
          <li>Trip Stops Fixed Issues</li>
        </ul>
        <h3>Version 2.2.0 - April 15, 2024</h3>
        <ul>
          <li>Trip List Version 2.2</li>
        </ul>
        <h3>Version 2.2.0 - April 10, 2024</h3>
        <ul>
          <li>Claims Update RD</li>
          <li>Quote Checkout</li>
          <li>Trip Outline Fixes</li>
          <li>Feedback Dispatch Filters</li>
          <li>Other Minor Fixes</li>
        </ul>
        <h3>Version 2.1.1 - March 22, 2024</h3>
        <ul>
          <li>Minor: Fixes Throught out the System
            <ul>
              <li>Note Valiation Removed</li>
              <li>Ship Cars Import</li>
              <li>Quickbooks Custom Maps</li>
              <li>Claims Assignee Issue</li>
              <li>Feedback List Update</li>
              <li>Route Outline</li>
            </ul>
          </li>
        </ul>
        <h3>Version 2.1.0 - March 12, 2024</h3>
        <ul>
          <li>Notes Moved to a Collection **major</li>
          <li>Notes Permissions **major</li>
          <li>Trips Routing Issues *minor</li>
          <li>BOL Layout Issue *minor</li>
        </ul>
        <h3>Version 2.0.9 - Feburary 28, 2024</h3>
        <ul>
          <li>Tracking Map - Ability to Track by Unit</li>
          <li>Trip Report Error Fixed</li>
          <li>Major* - Notes to Collection</li>
        </ul>
        <h3>Version 2.0.7 - Feburary 1, 2024</h3>
        <ul>
          <li>Shop Reports</li>
          <li>Bug Fix: Equipment trailer change in truck</li>
          <li>Bug Fix: Clear Contact from List on Delete</li>
          <li>Tasks: Don't trim task name in sidebar</li>
        </ul>
        <h3>Version 2.0.5 - January 23, 2024</h3>
        <ul>
          <li>Hide Customer Pay</li>
          <li>Recruiter Phone Layout Fix</li>
          <li>P&L Report Layout Fix</li>
          <li>Driver License Dispaly Fix</li>
        </ul>
        <h3>Version 2.0.4 - January 15, 2024</h3>
        <ul>
          <li>Phone Validatoin lib updated</li>
          <li>Carrier Default filter set on Trips</li>
          <li>Expenses Report Issue Fixed</li>
          <li>CRM UI overlay fixed</li>
          <li>Order Stops Dates Fix</li>
          <li>Order Details Edit Bugs Fixed</li>
        </ul>
        <h3>Version 2.0.3 - January 02, 2024</h3>
        <ul>
          <li>Trip Filler Warning</li>
          <li>[RD] Admin Release</li>
          <li>Sidebar titles fixed </li>
        </ul>
        <h3>Version 2.0.1 - January 02, 2024</h3>
        <ul>
          <li>Minor Fixes</li>
          <li>Order Count Criteria</li>
          <li>Loadbaord Pagination</li>
          <li>Dates of Stops</li>
          <li>Carrier adding Driver to Confirmed Order</li>
          <li>Carrier adding Driver to Confirmed Order</li>
        </ul>
        <h3>Version 2.0.1 - January 02, 2024</h3>
        <ul>
          <li>Pre-select on Bulk Update</li>
          <li>Sub-Status in leads filter fix</li>
        </ul>
          <ul>
          <li>Major Release</li>
          <li>Full System UI Redesign</li>
        </ul>
        <h3>Version 2.0.0 - December 21, 2023</h3>
        <ul>
          <li>Major Release</li>
          <li>Full System UI Redesign</li>
        </ul>
        <h3>Version 0.115.2 - October 25, 2023</h3>
        <ul>
          <li>Current Trip Driver Trip on Tracking Map</li>
          <li>Shop Service removed limit of 25 services</li>
        </ul>
        <h3>Version 0.115.1 - October 17, 2023</h3>
        <ul>
          <li>Route Meta Revenue and price per mile</li>
        </ul>
        <h3>Version 0.115.0 - October 15, 2023</h3>
        <ul>
          <li>Select Dispatch Trips</li>
          <li>Order Confirmation Filters</li>
          <li>Notify Last Vehicle Delivery on a Trip</li>
        </ul>
        <h3>Version 0.114.0 - September 6, 2023</h3>
        <ul>
          <li>Dispatch and Truck Trips Reports</li>
          <li>Trips to Route Multi Link</li>
          <li>Expense Trip link Warning</li>
          <li>Claims Tags Added (Driver Claims, Lawsuit)</li>
        </ul>
        <h3>Version 0.113.0 - August 2, 2023</h3>
        <ul>
          <li>Equipment Loan Number has been added next to the load rate.</li>
          <li>Invoice Terms have been updated to include the carrier name.</li>
          <li>Order Stop history has been added to new order creation.</li>
          <li>The alert box has been changed to a within-app modal window.</li>
        </ul>
        <h3>Version 0.112.0 - July 24, 2023</h3>
          <ul>
            <li>Implemented a "Quote Form by Company" feature, allowing users to send quotes directly to the order owner from the tracking page.</li>
            <li>Introduced new reports for "Drivers" and "Inventory" to provide comprehensive insights.</li>
            <li>Added "Car-Puller" as a new account type option for user accounts.</li>
            <li>Enhanced the Expenses feature by adding support for Sub Accounts, enabling more detailed financial tracking. Additionally, fixed an issue related to the order sorting when changing pages and resolved a layout problem.</li>
          </ul>
      </div> 
    </div>
  </div>
</div>
