import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Address, Contact, Order, User } from '@app/shared'
import { Store } from '@ngrx/store'
import * as fromModalStore from '@app/modals/store/actions/index'
import * as fromPublicStore from '@app/public/store/actions'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-carrier-order-booking-modal',
  templateUrl: './carrier-order-booking-modal.component.html',
  styleUrls: ['./carrier-order-booking-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarrierOrderBookingModalComponent {

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) {
    this.requestFormForm = this.fb.group({
      mcNumber: ['', Validators.required],
      contactName: ['', Validators.required],
      contactPhone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      contactEmail: ['', [Validators.required, Validators.email]],
      originDate: ['', Validators.required],
      destinationDate: ['', Validators.required],
      price: [0, [Validators.required, Validators.min(1)]],
      carrierNotes: ['']
    })
  }

  // @ViewChild('notesArea',{static: false})
  // notesArea: ElementRef

  @Input({required: true})
  set props(val:CarrierOrderBookingModalComponent['_props']) {
    if(val){
      this._props = val
      if(val.formData){
        console.log('form data',val.formData);

        this.requestFormForm.setValue(val.formData)
        // this.requestForm = val.formData
        // setTimeout(() => {
        //   if(this.notesArea?.nativeElement){
        //     this.notesArea.nativeElement.click()
        //   }
        // },500)
      }
      if(!val.formData?.contactName){
        this.requestFormForm.get('contactName').setValue(val.currentUser.name)
      }
    }
    // this.requestForm = {
    //   mcNumber: '123123',
    //   contactName: 'Tommy Column',
    //   contactPhone: '1231231231',
    //   contactEmail: 'admin@dd.com',
    //   originDate: "2024-10-05T00:00:00Z",
    //   destinationDate: "2024-10-10T00:00:00Z",
    //   price: 150000,
    //   carrierNotes: 'This are notes how you want to prefer with me, i don`t know i just prepered to you 1500 offer, please get it!'
    // }
  }
  _props:{
    order: Partial<Omit<Order,'destination' | 'order'>>,
    currentUser: User,
    carrier: User['company'],
    formData?: CarrierOrderBookingModalComponent['requestForm'],
    /**
     * `fromList` - when you will send request action - it will update all carrier order list, instead of one order
     */
    fromList?: boolean,
    /**
     * `filters` - temporary - move filters to store
     */
    filters?: {[key:string]: any}
  }



  get order(): Partial<Omit<Order,'destination' | 'order'>> {
    return this._props?.order
  }
  get currentUser(): User {
    return this._props?.currentUser
  }
  get carrier(): Contact {
    return this._props?.carrier
  }

  // Empty object, just for ts
  requestForm: {
    mcNumber: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    originDate: string;
    destinationDate: string;
    price: number;
    carrierNotes: string
  } = {
    mcNumber: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    originDate: '',
    destinationDate: '',
    price: 0,
    carrierNotes: ''
  }

  requestFormForm
  submitted: boolean = false

  // get requestFormValid(): boolean {
  //   for(let [key, value] of Object.entries(this.requestForm)){
  //     // If value is not phone, carrierNotes, carrier (name) - we don't allow to enter empty field
  //     if(key !== 'phone' && key !== 'carrierNotes' && key !== 'carrier'){
  //       if(!value){
  //         return false
  //       }
  //     }else if(key === 'phone'){
  //       // Special condition for phone (10 digits length)
  //       if(typeof value === 'string' && value.length !== 10){
  //         return false
  //       }
  //     }
  //   }
  //   return true
  // }

  sendRequest(){
    this.submitted = true
    console.log('Carrier form',this.requestForm)
    if(this.requestFormForm.valid){
      this.store.dispatch(new fromPublicStore.sendCarrierOrderOffer({
        orderNumber: this.order.quoteNumber,
        carrierId: this.currentUser.company._id,
        formData: this.requestFormForm.value,
        fromBook: true,
        fromList: this._props.fromList,
        filters: this._props.filters
      }))
      this.close()
    }

  }

  bookOrder() {
    this.store.dispatch(new fromPublicStore.bookCarrierOrder({
      orderNumber: this.order.quoteNumber,
      carrierId: this.currentUser.company._id
    }))
    this.close()
  }

  getGoogleRouteLink(){
    return `https://www.google.com/maps/dir/
    ${encodeURIComponent(this.order._local?.origin?.account?.address?.city?.trim() + ',' + this.order._local?.origin?.account?.address?.state?.trim())}
    /${encodeURIComponent(this.order._local?.destination?.account?.address?.city?.trim() + ',' + this.order._local?.destination?.account?.address?.state?.trim())}/`
  }

  acceptTerms: boolean = true

  close(){
    this.store.dispatch(new fromModalStore.CloseModal())
  }
}
